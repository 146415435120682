import React from "react";

interface ImageComponentProps {
  imgSrc: string;
  imgSide: string;
  imgCaption?: string;
  imgOrientation?: string;
  numberOfColumns: number;
}

function ImageOutsideComponent({
  imgSrc,
  imgSide,
  imgCaption,
  imgOrientation,
  numberOfColumns,
}: ImageComponentProps) {
  const widthPercentage = numberOfColumns <= 2 ? 50 : 100 / numberOfColumns;

  return (
    <div className="mb-[12px]">
      <div
        className={`w-[${widthPercentage}%] h-[300px] overflow-hidden  
      ${
        imgSide === "center"
          ? "mx-auto"
          : imgSide === "full"
          ? "w-full h-[300px]"
          : imgSide === "left"
          ? "pr-[10px]"
          : "pl-[10px] ml-auto"
      }`}
      >
        <img
          src={imgSrc}
          alt="description"
          className={`w-full h-full block object-${
            imgOrientation === "landscape" ? "cover" : "contain"
          }`}
        />
      </div>
      {imgCaption && (
        <div
          className={`${
            imgSide === "center"
              ? `mx-auto w-[${widthPercentage}%]`
              : imgSide === "full"
              ? "w-full"
              : imgSide === "left"
              ? `pr-[10px] w-[${widthPercentage}%]`
              : `pl-[10px] ml-auto w-[${widthPercentage}%]`
          }`}
        >
          <p className="bgPrimary p-[20px] text-white text-[13px] font-[400]">
            {imgCaption}
          </p>
        </div>
      )}
    </div>
  );
}

export default ImageOutsideComponent;
