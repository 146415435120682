import { Navigate, useNavigate, useParams } from "react-router-dom";
import { attemptGetConfirmation } from "../store/thunks/auth";
import { Error } from "../components/shared";
import { useAppDispatch } from "../store/hooks";
import { useServerError } from "../hooks/useServerError";

export default function RegisterConfirmationPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { serverError, handleServerError } = useServerError();
  const { token } = useParams<{ token: string }>();

  if (!token) {
    return <Navigate to='/home' replace />;
  }

  const handleSubmit = () => {
    dispatch(attemptGetConfirmation(token, navigate)).catch(handleServerError);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Email Confirmation</h2>
        <p className="mb-4 text-center">Click the button below to confirm your email.</p>
        <button
          onClick={handleSubmit}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Confirm Email
        </button>
        {serverError && <Error>{serverError}</Error>}
      </div>
    </div>
  );
}
