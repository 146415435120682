import { useState, useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { attemptGetUser } from "./store/thunks/user";
import {
  ConfirmPage,
  HomePage,
  ProfilePage,
  LoginPage,
  ResetPasswordRequestPage,
  ResetPasswordPage,
  LogoutPage,
  RegisterPage,
  ReportBuilderPage,
} from "./pages";
import Navbar from "./components/shared/Navbar";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { AuthRoute } from "./components/AuthRoute";
import { ProtectedRoute } from "./components/shared";
import { useTranslation } from "react-i18next";
import "./App.css";

export default function App() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.user.isAuth);

  useEffect(() => {
    dispatch(attemptGetUser())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return loading ? (
    <p>{t('loading')}</p>
  ) : (
    <>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <HomePage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/account/confirm/:token"
          element={
            <AuthRoute>
              <ConfirmPage />
            </AuthRoute>
          }
        />
        <Route
          path="/register"
          element={
            <AuthRoute>
              <RegisterPage />
            </AuthRoute>
          }
        />
        <Route
          path="/login"
          element={
            <AuthRoute>
              <LoginPage />
            </AuthRoute>
          }
        />
        <Route
          path="/login/forgot"
          element={
            <AuthRoute>
              <ResetPasswordRequestPage />
            </AuthRoute>
          }
        />
        <Route
          path="/login/reset/:token"
          element={
            <AuthRoute>
              <ResetPasswordPage />
            </AuthRoute>
          }
        />
        <Route
          path="/logout"
          element={
            <ProtectedRoute>
              <LogoutPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report-builder/:reportId"
          element={
            <ProtectedRoute>
              <ReportBuilderPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}
