import React from "react";
import { CSS, Tailwind } from "@fileforge/react-print";
import PageHeader from "./components/PageHeader";
import PageFooter from "./components/PageFooter";

interface ChapterCoverProps {
  isLandscape?: boolean;
  title: string;
  description: string;
  img: string;
  header: string[];
  isPdf?: boolean;
  companyName: string;
}

export const ChapterCover = ({
  isLandscape = true,
  title,
  description,
  img,
  header,
  isPdf = false,
  companyName,
}: ChapterCoverProps) => {
  const pageLayout = isLandscape ? "landscape" : "portrait";
  return (
    <React.Fragment>
      <CSS>
        {`
          @page {
            size: A4 ${pageLayout};
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0.4in;
          }

          .bgPrimary {
            background: #65B32E;
          }
          
          .bgSecondary {
            background: #0092d4;
          }

          .colorPrimary{
            color: #65B32E;
          }

          .colorSecondary{
            color: #0092d4;
          }
        `}
      </CSS>
      <Tailwind>
        <head></head>
        <body className={`${isPdf ? "" : "h-full"}`}>
          <PageHeader chapters={header} />
          <main
            className={`${
              isPdf ? "h-screen" : "h-[calc(100%-65px)]"
            } w-full pt-[50px] overflow-hidden`}
          >
            <div className="flex h-full w-full">
              <div className="w-[33%] h-full bgPrimary px-[30px] pt-[100px] text-white">
                <h1 className="font-bold text-[40px] mb-[15px] leading-10 break-words">
                  {title}
                </h1>
                <p className="break-words">{description}</p>
              </div>
              <div className="w-[67%] h-full">
                <img
                  src={
                    "https://lumentis-s3.s3.eu-central-1.amazonaws.com/chapter-cover.jpg"
                  }
                  alt="description"
                  className="w-full h-full object-cover object-top"
                />
              </div>
            </div>
          </main>
          <PageFooter companyName={companyName} />
        </body>
      </Tailwind>
    </React.Fragment>
  );
};
