import React, { useState, useEffect, useRef } from "react";
import { Resizable } from "re-resizable";
import { useTranslation } from "react-i18next";
import { getLayoutConfig } from "./templates/layoutConfigs";
import { getImageLayout } from "../shared/utils";
import {
  ReportMedia,
  ReportMediaType,
  Version,
  createDefaultMedia,
  createDefaultVersion,
} from "../shared/types";
import { Loader } from "lucide-react";

interface SubchapterInputProps {
  number: number;
  chapterIndex: number;
  subchapterIndex: number;
  title: string;
  subchapterDescription: string;
  versions: Version[];
  initialSelectedVersion: number;
  onUpdatePreview: (
    chapterIndex: number,
    subchapterIndex: number,
    userInputText: string,
    reportMedia: ReportMedia,
    layout: string,
    chartUrl: string
  ) => Promise<void>;
  onSaveAsNewVersion: (
    chapterIndex: number,
    subchapterIndex: number
  ) => Promise<void>;
  onVersionChange: (
    chapterIndex: number,
    subchapterIndex: number,
    newVersion: number
  ) => void;
  onLayoutChange: (
    chapterIndex: number,
    subchapterIndex: number,
    newLayout: string
  ) => void;
}

interface ImageDetails {
  imgWidth: number;
  imgHeight: number;
  orientation: string;
}

const SubchapterInput: React.FC<SubchapterInputProps> = ({
  number,
  chapterIndex,
  subchapterIndex,
  title,
  subchapterDescription,
  versions,
  onUpdatePreview,
  onSaveAsNewVersion,
  onVersionChange,
  initialSelectedVersion,
  onLayoutChange,
}) => {
  const { t } = useTranslation();
  const [currentVersionIndex, setCurrentVersionIndex] = useState<number>(
    initialSelectedVersion
  );
  const [layoutIndex, setLayoutIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false); // Added for loader

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [inputState, setInputState] = useState(() => {
    const initialVersion =
      versions[initialSelectedVersion] || createDefaultVersion();
    return {
      userInputText: initialVersion.userInputs.text.split("\n"),
      chartUrl: initialVersion.generatedContent.chartUrl || "",
      reportMedia: initialVersion.userInputs.media || createDefaultMedia(),
      selectedLayout: initialVersion.layout || "",
    };
  });

  // useEffect(() => {
  //   setCurrentVersionIndex(initialSelectedVersion);
  // }, [initialSelectedVersion]);

  useEffect(() => {
    const version = versions[currentVersionIndex] || createDefaultVersion();
    setInputState({
      userInputText: version.userInputs.text.split("\n"),
      chartUrl: version.generatedContent.chartUrl || "",
      reportMedia: version.userInputs.media || createDefaultMedia(),
      selectedLayout: version.layout,
    });
  }, [currentVersionIndex, versions]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (value.length === 1 && value !== "• ") {
      setInputState((prevState) => ({
        ...prevState,
        userInputText: [`• ${value}`],
      }));
    } else {
      setInputState((prevState) => ({
        ...prevState,
        userInputText: value.split("\n"),
      }));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const textarea = event.currentTarget;
      const cursorPosition = textarea.selectionStart;
      const newValue = `${textarea.value.substring(
        0,
        cursorPosition
      )}\n• ${textarea.value.substring(cursorPosition)}`;

      setInputState((prevState) => ({
        ...prevState,
        userInputText: newValue.split("\n"),
      }));

      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.selectionStart = cursorPosition + 3;
          textareaRef.current.selectionEnd = cursorPosition + 3;
        }
      }, 0);
    }
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const isImage = file.type.startsWith("image/");

      reader.onload = async (e) => {
        const result = e.target?.result as string;
        const type = isImage ? ReportMediaType.IMG : ReportMediaType.CSV;

        let imgDetails: ImageDetails = {
          imgWidth: 0,
          imgHeight: 0,
          orientation: "landscape",
        };
        if (isImage) {
          try {
            imgDetails = (await getImageLayout(result)) as ImageDetails;
          } catch (error) {
            console.error("Error getting image layout:", error);
          }
        }

        setInputState((prevState) => ({
          ...prevState,
          reportMedia: {
            ...prevState.reportMedia,
            filename: file.name,
            mediaContent: result,
            type,
            imgDetails: isImage ? imgDetails : prevState.reportMedia.imgDetails,
          },
        }));
      };

      if (file.type === "text/csv") {
        reader.readAsText(file);
      } else {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleCaptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputState((prevState) => ({
      ...prevState,
      reportMedia: {
        ...prevState.reportMedia,
        caption: event.target.value,
      },
    }));
  };

  const handleRemoveMedia = () => {
    setInputState((prevState) => ({
      ...prevState,
      reportMedia: createDefaultMedia(),
    }));
  };

  const handleUpdateClick = async () => {
    setIsLoading(true);
    await onUpdatePreview(
      chapterIndex,
      subchapterIndex,
      inputState.userInputText.join("\n"),
      inputState.reportMedia,
      inputState.selectedLayout,
      inputState.chartUrl
    );
    setIsLoading(false);
  };

  const handleSaveAsNewVersionClick = async () => {
    setIsLoading(true);
    await onSaveAsNewVersion(chapterIndex, subchapterIndex);
    const newVersionNumber = versions.length;
    setCurrentVersionIndex(newVersionNumber - 1);
    onVersionChange(chapterIndex, subchapterIndex, newVersionNumber - 1);
    setIsLoading(false);
  };

  const handleVersionChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newVersion = Number(e.target.value);
    setCurrentVersionIndex(newVersion);
    onVersionChange(chapterIndex, subchapterIndex, newVersion);
  };

  const handleShuffleLayout = () => {
    setIsLoading(true);
    setLayoutIndex((prevIndex) => {
      const applicableLayouts = getLayoutConfig(
        inputState.reportMedia?.imgDetails?.imgWidth,
        inputState.reportMedia?.imgDetails?.imgHeight,
        inputState.reportMedia?.imgDetails?.orientation,
        inputState.reportMedia?.type
      );
      const newIndex = (prevIndex + 1) % applicableLayouts.length;
      const newLayout = applicableLayouts[newIndex].id;
      setInputState((prevState) => ({
        ...prevState,
        selectedLayout: newLayout,
      }));
      onLayoutChange(chapterIndex, subchapterIndex, newLayout);
      setIsLoading(false);
      return newIndex;
    });
  };

  return (
    <div
      id={number.toString()}
      className="relative border-2 rounded-lg overflow-hidden h-full flex flex-col"
    >
      <div className="bg-white p-4 border-b-2 border-gray-300 flex justify-between items-center">
        <h3 className="text-lg font-semibold">{title}</h3>
        <div className="flex items-center space-x-2 ml-auto">
          <select
            id={`versionSelect-${chapterIndex}-${subchapterIndex}`}
            value={currentVersionIndex}
            onChange={handleVersionChange}
            className="mt-1 block pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md bg-white text-gray-900"
          >
            {versions.map((_, index) => (
              <option key={index} value={index} className="text-gray-700">
                {`Version ${index}`}
              </option>
            ))}
          </select>
          <button
            onClick={handleSaveAsNewVersionClick}
            className="bg-custom-blue hover-bg-custom-blue text-white py-2 px-6 rounded mt-2"
          >
            {isLoading ? <Loader /> : t("saveAsNewVersion")}
          </button>
        </div>
      </div>
      <div className="flex w-full flex-grow border-t border-gray-300">
        <Resizable
          className="border-r-2 border-gray-300 flex items-center justify-center min-w-[50%]"
          defaultSize={{ width: "50%", height: "100%" }}
          enable={{ right: true }}
        >
          <div className="p-4 w-full h-full flex flex-col">
            <textarea
              ref={textareaRef}
              value={inputState.userInputText.join("\n")}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              rows={5}
              className="w-full mb-2 p-2 border rounded flex-grow"
              placeholder="Enter bullet points here..."
            />
            <div className="my-2 flex items-center justify-between">
              <div>
                <input
                  type="file"
                  accept=".jpeg, .jpg, .png, .csv"
                  onChange={handleUpload}
                  className="hidden"
                  id={`file-upload-${chapterIndex}-${subchapterIndex}`}
                />
                <label
                  htmlFor={`file-upload-${chapterIndex}-${subchapterIndex}`}
                  className="cursor-pointer bg-white text-custom-green border border-gray-300 py-2 px-4 rounded-md shadow-sm hover:bg-gray-100"
                >
                  {t("uploadFile")}
                </label>
                <div className="text-sm text-gray-700 mt-2">
                  {inputState.reportMedia.filename}
                </div>
              </div>
              {inputState.reportMedia.mediaContent && (
                <button
                  onClick={handleRemoveMedia}
                  className="text-black text-xl leading-none ml-2"
                >
                  &times;
                </button>
              )}
            </div>
            <input
              type="text"
              value={inputState.reportMedia.caption}
              onChange={handleCaptionChange}
              placeholder={
                !inputState.reportMedia.mediaContent
                  ? ""
                  : inputState.reportMedia.type === ReportMediaType.CSV
                  ? t("csvTextPlaceholder")
                  : t("imageCaptionPlaceholder")
              }
              className="w-full mb-2 p-2 border rounded"
            />
          </div>
        </Resizable>
        <div className="p-4 bg-gray-100 flex-1 flex flex-col justify-between">
          <div>
            <h4 className="text-md font-semibold">{t("details")}</h4>
            <p>{subchapterDescription}</p>
          </div>
          <div className="flex space-x-2 justify-between">
            <button
              onClick={handleUpdateClick}
              className="bg-custom-green hover-bg-custom-green text-white py-2 px-6 rounded mt-2"
              style={{ backgroundColor: "#7FB241" }}
            >
              {isLoading ? <Loader /> : t("updatePreview")}
            </button>

            <button
              onClick={handleShuffleLayout}
              className="bg-custom-blue hover-bg-custom-blue text-white py-2 px-6 rounded mt-2"
            >
              {isLoading ? <Loader /> : t("shuffleLayout")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubchapterInput;
