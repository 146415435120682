export function getImageLayout(src: string) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = src;

    img.onload = () => {
      let imgDetails = {
        imgWidth: img.naturalWidth,
        imgHeight: img.naturalHeight,
        orientation: "",
      };
      if (img.naturalWidth > img.naturalHeight) {
        imgDetails.orientation = "landscape";
      } else if (img.naturalWidth < img.naturalHeight) {
        imgDetails.orientation = "portrait";
      } else {
        imgDetails.orientation = "even";
      }

      resolve(imgDetails);
    };

    img.onerror = () => {
      reject(new Error("Image failed to load"));
    };
  });
}

export function getLanguageName(code: string): string {
  const languageMap: { [key: string]: string } = {
    en: "English",
    de: "German",
  };

  return languageMap[code.toLowerCase()] || languageMap["en"];
}
