export enum ReportMediaType {
  CSV = "CSV",
  IMG = "IMG",
}

export interface ReportMedia {
  type: ReportMediaType;
  mediaContent: string;
  caption: string;
  filename: string;
  orientation?: string; // Optional and only applicable for images
  imgDetails: {
    imgWidth: number;
    imgHeight: number;
    orientation: string;
  };
}

export interface UserInput {
  text: string;
  media: ReportMedia;
}

export interface GeneratedContent {
  text: string;
  chartUrl?: string; // Optional and only applicable for charts from CSV data
}

export interface Version {
  number: number;
  layout: string;
  userInputs: UserInput;
  generatedContent: GeneratedContent;
  createdAt: Date;
}

export interface Subchapter {
  number: number;
  title: string;
  description: string;
  versions: Version[];
}

export interface Chapter {
  number: number;
  title: string;
  subtitle: string;
  description: string;
  subchapters: Subchapter[];
}

export interface SubchapterConfig {
  number: number;
  title: string;
  description: string;
  versions: Version[];
}

export interface ChapterConfig {
  number: number;
  title: string;
  subtitle: string;
  description: string;
  subchapters: SubchapterConfig[];
}

export interface ReportConfig {
  description: string;
  isLandscape: boolean;
  bgColor: string;
  chapters: ChapterConfig[];
}

export const createDefaultMedia = (): ReportMedia => ({
  type: ReportMediaType.IMG,
  mediaContent: "",
  caption: "",
  filename: "",
  imgDetails: {
    imgWidth: 0,
    imgHeight: 0,
    orientation: "",
  },
});

export const createDefaultUserInput = (): UserInput => ({
  text: "",
  media: createDefaultMedia(),
});

export const createDefaultGeneratedContent = (): GeneratedContent => ({
  text: "",
  chartUrl: "",
});

export const createDefaultVersion = (): Version => ({
  number: 0,
  layout: "",
  userInputs: createDefaultUserInput(),
  generatedContent: createDefaultGeneratedContent(),
  createdAt: new Date(),
});
