import { LOGIN_USER, LOGOUT_USER, SET_USER, RESET_USER, UserAction, UserWithReports, Report, User } from "../actions/user";

export type UserState = {
  isAuth: boolean;
  user: User | null;
  reports: Report[];
};

const initialState: UserState = {
  isAuth: false,
  user: null,
  reports: [],
};

export default function user(state = initialState, action: UserAction<UserWithReports>): UserState {
  switch (action.type) {
    case LOGIN_USER:
      return {
        user: action.payload?.user || null,
        isAuth: true,
        reports: action.payload?.reports || [],
      };
    case LOGOUT_USER:
      return {
        isAuth: false,
        user: null,
        reports: [],
      };
    case SET_USER:
      return {
        user: action.payload?.user || null,
        isAuth: true,
        reports: action.payload?.reports || [],
      };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
}
