import { PageTop } from "@fileforge/react-print";

function PageHeader({
  chapters,
  className,
}: {
  chapters: string[];
  className?: string;
}) {
  return (
    <PageTop className={className}>
      <div className="pt-[20px] flex items-center justify-between ">
        <div className="flex flex-wrap colorPrimary text-[10px]">
          {chapters.map((name) => {
            return (
              <p key={name} className="mr-[28px]">
                {name}
              </p>
            );
          })}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#008b46"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-menu"
        >
          <line x1="4" x2="20" y1="12" y2="12" />
          <line x1="4" x2="20" y1="6" y2="6" />
          <line x1="4" x2="20" y1="18" y2="18" />
        </svg>
      </div>
    </PageTop>
  );
}

export default PageHeader;
