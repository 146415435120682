import { Credentials, User, Report } from "../store/actions/user";
import http from "../services/httpService";

const postLogin = async (credentials: Credentials) => {
  const response = await http.post<{ user: User; reports: any[] }>("/auth/login", credentials);
  return response.data;
};

const sendResetPasswordLink = (email: string) => http.post("/auth/login/forgot", { email });

const resetPassword = (password: string, token: string) =>
  http.post<void>(`/auth/login/reset/${token}`, { password });

const postLogout = () => http.post<void>("/auth/logout");

const postUser = async (newUser: User) => {
  const response = await http.post<{ user: User; reports: Report[] }>("/user/register", newUser);
  return response.data;
};

const getConfirmation = (token: string) => http.get<void>(`/auth/confirmation/${token}`);

const resendConfirmation = (email: string) => http.post<void>("/auth/send-confirmation", { email });

const resetRegister = (email: string) => http.post<void>("/user/register/cancel", { email });

const getUser = () => http.get<{ user: User }>("/user");

export {
  postLogin,
  sendResetPasswordLink,
  resetPassword,
  postLogout,
  postUser,
  getConfirmation,
  resendConfirmation,
  getUser,
  resetRegister,
};
