import React from "react";

interface ImageComponentProps {
  src?: string;
  imgSide?: string;
  numberOfColumns: number;
  imgCaption?: string;
  imgDetails?: {
    imgWidth: number;
    imgHeight: number;
    orientation: string;
  };
}

function ImageComponent({
  src,
  imgSide,
  imgCaption,
  imgDetails,
  numberOfColumns,
}: ImageComponentProps) {
  return (
    <div
      className={` ${
        imgDetails?.orientation === "landscape"
          ? "w-full h-auto"
          : `w-full max-h-max flex ${
              numberOfColumns <= 2 ? "flex-grow" : "flex-col"
            }`
      } mb-[10px] overflow-hidden`}
    >
      <img
        src={src}
        alt="description"
        className={`
          ${
            imgDetails?.orientation === "landscape"
              ? "w-full h-full"
              : "h-[325px]"
          } 
          object-contain object-${numberOfColumns <= 2 ? imgSide : "center"} 
        ${imgCaption ? "" : "mb-[5px]"}
        ${imgSide === "left" ? "order-1" : "order-2"}
        `}
      />
      {imgCaption && (
        <div
          className={`
            flex-1 
            bgPrimary
            text-white
            p-[20px]
            
            ${
              imgDetails?.orientation === "landscape"
                ? ""
                : numberOfColumns <= 2
                ? "px-3"
                : ""
            } 
            ${imgSide === "left" ? "order-2" : "order-1"}
        `}
        >
          <p className="text-[13px] font-[400]">{imgCaption}</p>
        </div>
      )}
    </div>
  );
}

export default ImageComponent;
