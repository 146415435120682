import React from "react";
import { Chapter, ReportConfig, ReportMedia } from "../shared/types";
import SubchapterInput from "./SubchapterInput";

interface ChapterListProps {
  chapters: Chapter[];
  reportConfig: ReportConfig;
  onUpdatePreview: (
    chapterIndex: number,
    subchapterIndex: number,
    userInputText: string,
    reportMedia: ReportMedia,
    layout: string,
    chartUrl: string
  ) => Promise<void>;
  onSaveAsNewVersion: (
    chapterIndex: number,
    subchapterIndex: number
  ) => Promise<void>;
  onVersionChange: (
    chapterIndex: number,
    subchapterIndex: number,
    selectedVersion: number
  ) => Promise<void>;
  selectedVersions: { [key: string]: number };
  onLayoutChange: (
    chapterIndex: number,
    subchapterIndex: number,
    layout: string
  ) => void;
}

const ChapterList: React.FC<ChapterListProps> = ({
  chapters,
  reportConfig,
  onUpdatePreview,
  onSaveAsNewVersion,
  onVersionChange,
  selectedVersions,
  onLayoutChange,
}) => {
  return (
    <div className="flex-1 px-4" style={{ maxWidth: "50%" }}>
      {chapters.map((chapter, chapterIndex) => (
        <div key={chapterIndex}>
          <div className="chapter-description mb-4 h-[calc((50vw-42px)/1.41)] flex flex-col p-4 border border-gray-300 rounded-lg">
            <h1 className="text-3xl font-semibold text-green-600 mb-4">
              {chapter.title}
            </h1>
            <h2 className="text-xl font-medium text-gray-700 mb-4">
              {chapter.subtitle}
            </h2>
            <div className="w-full flex-1 p-2 border border-gray-300 rounded-md">
              <p className="p-4">{chapter.description}</p>
            </div>
          </div>
          {chapter.subchapters.map((subchapter, subIndex) => {
            const subchapterConfig =
              reportConfig.chapters[chapterIndex]?.subchapters[subIndex];
            const key = `${chapterIndex}-${subIndex}`;
            return (
              <div
                key={`subchapter-${chapterIndex}-${subIndex}`}
                className="mb-4 h-[calc((50vw-42px)/1.41)] flex flex-col"
              >
                <SubchapterInput
                  key={subchapter.number}
                  chapterIndex={chapterIndex}
                  subchapterIndex={subIndex}
                  title={subchapter?.title}
                  onUpdatePreview={onUpdatePreview}
                  onSaveAsNewVersion={() =>
                    onSaveAsNewVersion(chapterIndex, subIndex)
                  }
                  onVersionChange={onVersionChange}
                  onLayoutChange={onLayoutChange}
                  number={subchapter.number}
                  subchapterDescription={subchapterConfig?.description || ""}
                  versions={subchapter.versions}
                  initialSelectedVersion={
                    selectedVersions[key] ?? subchapter.versions.length - 1
                  }
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default ChapterList;
