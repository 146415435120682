import React from "react";
import { useTranslation } from "react-i18next";

interface PdfRendererProps {
  handleRenderPdf: () => void;
}

const PdfRenderer: React.FC<PdfRendererProps> = ({ handleRenderPdf }) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-end">
      <button
        onClick={handleRenderPdf}
        className="bg-custom-green hover-bg-custom-green text-white py-2 px-6 rounded"
        style={{ backgroundColor: "#7FB241" }}
      >
        {t("renderPdf")}
      </button>
    </div>
  );
};

export default PdfRenderer;
