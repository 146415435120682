import React from "react";
import ImageComponent from "./Image";

interface ColumnsProps {
  numberOfColumns: number;
  content: { heading: string; paragraph: string }[] | null;
  imgSide?: string;
  img?: string;
  caption?: string;
  isPdf?: boolean;
  imgOrder: string;
  imgOutside?: boolean;
  imgDetails?: {
    imgWidth: number;
    imgHeight: number;
    orientation: string;
  };
}

function Columns({
  numberOfColumns,
  content,
  img,
  imgSide,
  imgDetails,
  caption,
  isPdf,
  imgOrder,
  imgOutside,
}: ColumnsProps) {
  return (
    <div
      className={`columns-${numberOfColumns} ${
        isPdf ? "" : imgOutside ? "flex-1" : "h-[calc(100%-85px)]"
      }`}
      style={
        isPdf
          ? { columnFill: "auto" }
          : imgOutside
          ? {}
          : { columnFill: "auto" }
      }
    >
      {img && !imgOutside && imgOrder === "first" && (
        <ImageComponent
          numberOfColumns={numberOfColumns}
          src={img}
          imgCaption={caption}
          imgDetails={imgDetails}
          imgSide={imgSide}
        />
      )}
      {content &&
        content?.length > 0 &&
        content?.map((topic, index) => {
          return (
            <div key={index}>
              {topic?.heading && (
                <p className="text-[16px] font-[500] colorPrimary">
                  {topic.heading}
                </p>
              )}
              {topic?.paragraph && (
                <p className="text-[13px] mb-[12px] font-[400]">
                  {topic.paragraph}
                </p>
              )}
            </div>
          );
        })}
      {img && !imgOutside && imgOrder === "last" && (
        <ImageComponent
          numberOfColumns={numberOfColumns}
          src={img}
          imgCaption={caption}
          imgDetails={imgDetails}
          imgSide={imgSide}
        />
      )}
    </div>
  );
}

export default Columns;
