import React from "react";
import { CSS, PageBreak, Tailwind } from "@fileforge/react-print";
import PageHeader from "./components/PageHeader";
import PageFooter from "./components/PageFooter";
import Columns from "./components/Columns";
import ImageOutsideComponent from "./components/ImageOutside";

// export type ImageConfigType = {
//   useInBetween: boolean;
//   imgOrientation: string;
//   imgSide: string;
// };
//
// export type DataType = {
//   content: string[];
//   imgData: {
//     imgSrc: string;
//     imgCaption: string;
//   };
// };

interface SubchapterContentProps {
  isLandscape?: boolean;
  numberOfColumns?: number;
  imgSide?: string;
  imgDetails?: {
    imgWidth: number;
    imgHeight: number;
    orientation: string;
  };
  imgOutside?: boolean;
  img?: string;
  caption?: string;
  content: string;
  header: string[];
  isPdf?: boolean;
  title?: string;
  imgOrder?: string;
  companyName: string;
}

interface FormattedContent {
  heading: string;
  paragraph: string;
}

function formattedContent(text: string): FormattedContent[] | null {
  if (!text) return null;

  let sections: string[] = text.split(/\n(?=##)/);
  let result: FormattedContent[] = [];

  sections.forEach((section) => {
    section = section.trim();
    let heading: string = "";
    let paragraph: string = "";

    let lines: string[] = section.split(/\n/);
    let headingLineIndex: number = -1;

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i].trim();
      if (line.startsWith("##")) {
        headingLineIndex = i;
        heading = line.substring(2).trim();
        break;
      }
    }

    if (headingLineIndex !== -1) {
      let paragraphLines: string[] = [];
      for (let i = headingLineIndex + 1; i < lines.length; i++) {
        let line = lines[i].trim();
        if (line.startsWith("##")) {
          break;
        }
        paragraphLines.push(line);
      }
      paragraph = paragraphLines.join(" ").trim();
    }

    let sectionObject: FormattedContent = {
      heading: heading || "",
      paragraph: paragraph || "",
    };

    if (heading !== "" || paragraph !== "") {
      result.push(sectionObject);
    }
  });

  return result;
}

export const SubchapterContent = ({
  isLandscape = true,
  numberOfColumns = 2,
  img,
  imgSide = "center",
  imgOrder = "first",
  imgOutside = false,
  imgDetails,
  caption,
  content,
  header,
  isPdf = false,
  title,
  companyName,
}: SubchapterContentProps) => {
  const pageLayout = isLandscape ? "landscape" : "portrait";
  const parsedContent: FormattedContent[] | null = formattedContent(content);

  return (
    <React.Fragment>
      <CSS>
        {`
          @page {
            size: A4 ${pageLayout};
            margin-left: 0.3in;
            margin-right: 0.3in;
            margin-bottom: 0.4in;
          }

          .bgPrimary {
            background: #65B32E;
          }
          
          .bgSecondary {
            background: #0092d4;
          }

          .colorPrimary{
            color: #65B32E;
          }

          .colorSecondary{
            color: #0092d4;
          }
        `}
      </CSS>
      <Tailwind>
        <head>
          {/*<link rel="stylesheet" href="../styles/styles.css" />*/}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap"
            rel="stylesheet"
          ></link>
        </head>
        <body
          style={{ fontFamily: "Roboto Condensed, sans-serif" }}
          className={`${isPdf ? "" : "h-full"}`}
        >
          <PageHeader chapters={header} className="bg-white" />
          <main
            className={`py-[40px] overflow-hidden 
               ${
                 isPdf
                   ? `min-h-screen`
                   : imgOutside
                   ? "h-[calc(100%-66px)] flex flex-col"
                   : "h-[calc(100%-66px)]"
               } 
              `}
          >
            {title && (
              <h1 className="text-[40px] colorPrimary font-[300] mb-[25px]">
                {title}
              </h1>
            )}
            {img && imgOutside && (
              <ImageOutsideComponent
                imgSrc={img}
                imgSide={imgSide}
                imgOrientation={imgDetails?.orientation}
                numberOfColumns={numberOfColumns}
                imgCaption={caption}
              />
            )}
            <Columns
              numberOfColumns={numberOfColumns}
              content={parsedContent}
              img={img}
              caption={caption}
              imgDetails={imgDetails}
              imgSide={imgSide}
              isPdf={isPdf}
              imgOrder={imgOrder}
              imgOutside={imgOutside}
            />
          </main>
          <PageBreak />
          <PageFooter companyName={companyName} className="bg-white" />
        </body>
      </Tailwind>
    </React.Fragment>
  );
};
