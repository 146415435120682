import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { Report } from "../store/actions/user";
import { useTranslation } from "react-i18next";
import httpService from "../services/httpService";

export default function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const [reports, setReports] = useState<Report[]>([]);
  const [newReport, setNewReport] = useState({
    title: "",
    companyName: "",
    lang: "EN",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await httpService.get("/report");
        setReports(response.data);
      } catch (error) {
        console.error("Error fetching reports:", error);
      }
    };
    if (user) {
      fetchReports();
    }
  }, [user]);

  const handleCreateReport = async () => {
    try {
      const response = await httpService.post("/report", newReport);
      navigate(`/report-builder/${response.data._id}`);
    } catch (error) {
      console.error("Error creating report:", error);
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleDeleteReport = async (reportId: string) => {
    if (window.confirm(t("deleteReportConfirmation"))) {
      try {
        await httpService.delete(`/report/${reportId}`);
        setReports(reports.filter((report) => report._id !== reportId));
      } catch (error) {
        console.error("Error deleting report:", error);
      }
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center p-4">
      <h1 className="text-2xl mt-5 mb-6 text-center">{t("myReports")}</h1>
      <div className="w-full max-w-5xl px-4">
        <table className="min-w-full bg-white border border-gray-200 mb-8 min-h-[300px]">
          <thead>
            <tr className="bg-gray-200 text-left text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-center">{t("title")}</th>
              <th className="py-3 px-6 text-center">{t("companyName")}</th>
              <th className="py-3 px-6 text-center">{t("actions")}</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {reports.length > 0 ? (
              reports.map((report) => (
                <tr
                  key={report._id}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="py-3 px-6 text-center">{report.title}</td>
                  <td className="py-3 px-6 text-center">
                    {report.companyName}
                  </td>
                  <td className="py-3 px-6 text-center">
                    <div className="flex justify-center space-x-2">
                      <button
                        onClick={() =>
                          navigate(`/report-builder/${report._id}`)
                        }
                        className="bg-custom-blue hover-bg-custom-blue text-white font-bold py-2 px-4 rounded"
                      >
                        {t("edit")}
                      </button>
                      <button
                        onClick={() => handleDeleteReport(report._id)}
                        className="bg-custom-red hover-bg-custom-red text-white font-bold py-2 px-4 rounded"
                      >
                        {t("delete")}
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center py-6">
                  {t("noReportsAvailable")}:
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-custom-green hover-bg-custom-green text-white py-2 px-4 rounded"
      >
        {t("createNewReport")}
      </button>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
            <h2 className="text-2xl mb-4">{t("createNewReport")}</h2>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm mb-2"
                htmlFor="title"
              >
                {t("title")}
              </label>
              <input
                type="text"
                id="title"
                value={newReport.title}
                onChange={(e) =>
                  setNewReport({ ...newReport, title: e.target.value })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm mb-2"
                htmlFor="companyName"
              >
                {t("companyName")}
              </label>
              <input
                type="text"
                id="companyName"
                value={newReport.companyName}
                onChange={(e) =>
                  setNewReport({ ...newReport, companyName: e.target.value })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm mb-2"
                htmlFor="language"
              >
                {t("language")}
              </label>
              <select
                id="language"
                value={newReport.lang}
                onChange={(e) =>
                  setNewReport({ ...newReport, lang: e.target.value })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="EN">English</option>
                <option value="DE">Deutsch</option>
              </select>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleCreateReport}
                className="bg-custom-green hover-bg-custom-green text-white py-2 px-4 rounded mr-2"
              >
                {t("submit")}
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-custom-red hover-bg-custom-red text-white py-2 px-4 rounded"
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
