import { PageBottom, PageNumber } from "@fileforge/react-print";

interface PageFooterProps {
  className?: string;
  companyName: string;
}

function PageFooter({ className, companyName }: PageFooterProps) {
  return (
    <PageBottom className={className}>
      <div className="text-xs flex items-center justify-between">
        <div>{companyName} 2024</div>
        <p className="font-bold">
          <PageNumber />
        </p>
      </div>
    </PageBottom>
  );
}

export default PageFooter;
