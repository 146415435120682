import { useEffect, useState } from "react";
import { compile } from "@fileforge/react-print";
import {
  Chapter,
  ReportConfig,
  ReportMedia,
  ReportMediaType,
} from "../../shared/types";
import ChapterList from "../../components/ChapterList";
import ChapterPreview from "../../components/ChapterPreview";
import PdfRenderer from "../../components/PdfRenderer";
import { Loader } from "../../components/shared";
import { useParams } from "react-router-dom";
import httpService from "../../services/httpService";
import { getLanguageName } from "../../shared/utils";

export default function ReportBuilderPage() {
  const [chapterData, setChapterData] = useState<Chapter[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { reportId } = useParams<{ reportId: string }>();
  const [reportConfig, setReportConfig] = useState<ReportConfig | null>(null);
  const [companyName, setCompanyName] = useState<string>("");
  const [reportLanguage, setReportLanguage] = useState<string>("");
  const [selectedVersions, setSelectedVersions] = useState<{
    [key: string]: number;
  }>({});
  const [tempVersion, setTempVersion] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const fetchReport = async () => {
      if (reportId) {
        try {
          const response = await httpService.get(`/report/${reportId}`);
          const { chapters, reportConfigId, companyName, language } =
            response.data;
          const reportConfigResponse = await httpService.get(
            `/report/reportConfig/${reportConfigId}`
          );
          const fetchedReportConfig = reportConfigResponse.data;
          setReportConfig(fetchedReportConfig);
          setCompanyName(companyName);
          setReportLanguage(language);
          // Merge chapter data from report and reportConfig
          const mergedChapters = chapters.map((chapter: any, index: number) => {
            const configChapter = fetchedReportConfig.chapters[index] || {};
            return {
              ...chapter,
              title: configChapter.title || chapter.title,
              subtitle: configChapter.subtitle || chapter.subtitle,
              description: configChapter.description || chapter.description,
            };
          });
          setChapterData(mergedChapters);
        } catch (err) {
          setError(err instanceof Error ? err.message : String(err));
          console.error("Error fetching report:", err);
        }
      }
    };
    fetchReport();
  }, [reportId]);

  const handleUpdatePreview = async (
    chapterIndex: number,
    subchapterIndex: number,
    userInputText: string,
    reportMedia: ReportMedia,
    layout: string,
    chartUrl: string
  ) => {
    setLoading(true);
    try {
      const key = `${chapterIndex}-${subchapterIndex}`;
      const chapterTitle = chapterData[chapterIndex]?.title || "";
      const subchapterTitle =
        chapterData[chapterIndex]?.subchapters[subchapterIndex]?.title || "";

      const previousSubchapterDescription =
        subchapterIndex > 0
          ? reportConfig?.chapters[chapterIndex]?.subchapters[
              subchapterIndex - 1
            ]?.description || ""
          : "NA since this is the first subchapter for this chapter";

      const nextSubchapterTitle =
        subchapterIndex < chapterData[chapterIndex].subchapters.length - 1
          ? reportConfig?.chapters[chapterIndex]?.subchapters[
              subchapterIndex + 1
            ]?.title || ""
          : "NA since this is the last subchapter for this chapter";

      let contentResponse;
      const contentResponsePromise = httpService.post(
        "/report/generateContent",
        {
          text: userInputText,
          chapterTitle,
          subchapterTitle,
          companyName,
          language: getLanguageName(reportLanguage),
          previousSubchapterDescription,
          nextSubchapterTitle,
        }
      );
      if (
        reportMedia.type === ReportMediaType.CSV &&
        reportMedia.mediaContent
      ) {
        const chartResponsePromise = httpService.post("/report/generateChart", {
          csvData: reportMedia.mediaContent,
          csvCaption: reportMedia.caption,
        });
        const [contentResponseResult, chartResponse] = await Promise.all([
          contentResponsePromise,
          chartResponsePromise,
        ]);
        contentResponse = contentResponseResult;
        chartUrl = chartResponse.data.chartUrl;
      } else {
        contentResponse = await contentResponsePromise;
      }

      const newVersion = {
        number:
          chapterData[chapterIndex]?.subchapters[subchapterIndex]?.versions
            .length || 0,
        layout,
        userInputs: {
          text: userInputText,
          media: reportMedia,
        },
        generatedContent: {
          text: contentResponse.data.generatedContent,
          chartUrl: chartUrl,
        },
        createdAt: new Date(),
      };

      setTempVersion((prev) => ({ ...prev, [key]: newVersion }));
      setLoading(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error generating content:", err);
      setLoading(false);
    }
  };

  const handleSaveAsNewVersion = async (
    chapterIndex: number,
    subchapterIndex: number
  ) => {
    setLoading(true);
    try {
      const key = `${chapterIndex}-${subchapterIndex}`;
      const newVersion = tempVersion[key];
      if (!newVersion) {
        throw new Error("Please update preview first");
      }

      const updatedChapters: Chapter[] = [...chapterData];
      const newVersionNumber =
        updatedChapters[chapterIndex].subchapters[subchapterIndex].versions
          .length;
      newVersion.number = newVersionNumber;
      updatedChapters[chapterIndex].subchapters[subchapterIndex].versions.push(
        newVersion
      );
      setChapterData(updatedChapters);
      // Update the selectedVersions state
      setSelectedVersions((prev) => ({ ...prev, [key]: newVersionNumber }));
      // Preserve the temporary version as the preview content
      setTempVersion((prev) => ({ ...prev, [key]: newVersion }));

      await httpService.post("/report/saveNewVersion", {
        reportId,
        chapterIndex,
        subchapterIndex,
        newVersion,
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error("Error saving new version:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleVersionChange = async (
    chapterIndex: number,
    subchapterIndex: number,
    selectedVersion: number
  ) => {
    const key = `${chapterIndex}-${subchapterIndex}`;
    setSelectedVersions((prev) => ({ ...prev, [key]: selectedVersion }));
    setTempVersion((prev) => {
      const selectedVersionData =
        chapterData[chapterIndex].subchapters[subchapterIndex].versions[
          selectedVersion
        ];
      return { ...prev, [key]: selectedVersionData };
    });
  };

  const handleLayoutChange = (
    chapterIndex: number,
    subchapterIndex: number,
    layout: string
  ) => {
    setTempVersion((prev) => {
      const key = `${chapterIndex}-${subchapterIndex}`;
      const updatedVersion = { ...prev[key], layout };
      return { ...prev, [key]: updatedVersion };
    });
  };

  const handleRenderPdf = async () => {
    setLoading(true);
    try {
      const compiledHtml = await compile(getPdfHtmlContent(true));
      const response = await httpService.post("/report/generatePdf", {
        htmlContent: compiledHtml,
      });
      const { link } = response.data;
      window.open(link, "_blank");
    } catch (error) {
      console.error("Error generating document:", error);
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setLoading(false);
    }
  };

  const getPdfHtmlContent = (isPdf: boolean = false) => {
    return (
      <ChapterPreview
        key={`${chapterData.length}-${Object.keys(selectedVersions).length}`}
        chapters={chapterData}
        header={
          reportConfig ? reportConfig.chapters.map((_: any) => _.title) : []
        }
        isPdf={isPdf}
        selectedVersions={selectedVersions}
        tempVersion={tempVersion}
        companyName={companyName}
      />
    );
  };

  if (!reportConfig) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-white relative">
      {loading && <Loader />}

      {/* Container for the PDF Render button */}
      <div className="flex justify-end p-4">
        <PdfRenderer handleRenderPdf={handleRenderPdf} />
      </div>

      {/* Main flex container for ChapterList and right content */}
      <div className="flex flex-grow">
        <ChapterList
          chapters={chapterData}
          onUpdatePreview={handleUpdatePreview}
          onSaveAsNewVersion={handleSaveAsNewVersion}
          reportConfig={reportConfig}
          onVersionChange={handleVersionChange}
          selectedVersions={selectedVersions}
          onLayoutChange={handleLayoutChange}
        />
        <div
          className="flex-1 px-4"
          style={{ maxWidth: "50%", position: "relative" }}
        >
          {/* Right content */}
          <div className="overflow-y-auto h-full">
            {getPdfHtmlContent(false)}
          </div>
        </div>
      </div>

      {/* Error handling */}
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
}
