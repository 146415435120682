import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { attemptLogin } from "../store/thunks/auth";
import { Error } from "../components/shared";
import { Credentials } from "../store/actions/user";
import { useAppDispatch } from "../store/hooks";
import { useServerError } from "../hooks/useServerError";
import {useTranslation} from "react-i18next";

type LoginFormValues = Credentials;

export default function LoginPage() {
  const { t } = useTranslation();
  const { serverError, handleServerError } = useServerError();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValues: LoginFormValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string().min(3).max(50).required("Required"),
    password: Yup.string().min(5).max(255).required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values: LoginFormValues) => {
    dispatch(attemptLogin(values, navigate)).catch(handleServerError);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">{t("login")}</h2>
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              {...register("username")}
              id="username"
              type="text"
              placeholder={t("username")}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.username && <Error>{errors.username.message}</Error>}
          </div>
          <div>
            <input
              {...register("password")}
              id="password"
              type="password"
              placeholder={t("password")}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.password && <Error>{errors.password.message}</Error>}
          </div>
          <div className="flex items-center justify-between">
            <Link to="/login/forgot" className="text-sm text-indigo-600 hover:text-indigo-500">
              {t("forgotYourPassword")}?
            </Link>
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {t("login")}
            </button>
          </div>
          {serverError && <Error>{serverError}</Error>}
        </form>
        <div className="mt-6 text-center">
          <b>{t("or")}</b>
          <Link to="/register" className="text-indigo-600 hover:text-indigo-500 ml-2">
            {t("signup")}
          </Link>
        </div>
      </div>
    </div>
  );
}
