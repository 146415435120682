export enum LayoutType {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
  ONLY_TEXT = "only_text",
  FULL_IMAGE = "full_image",
}

export const layoutConfigs = [
  {
    id: "layout-1",
    type: LayoutType.LANDSCAPE,
    isLandscape: true,
    numberOfColumns: 2,
    imgSide: "right",
    imgOrder: "first",
    imgOutside: false,
  },
  {
    id: "layout-2",
    type: LayoutType.LANDSCAPE,
    isLandscape: true,
    numberOfColumns: 2,
    imgSide: "right",
    imgOrder: "last",
    imgOutside: false,
  },
  {
    id: "layout-3",
    type: LayoutType.PORTRAIT,
    isLandscape: true,
    numberOfColumns: 2,
    imgSide: "right",
    imgOrder: "first",
    imgOutside: false,
  },
  {
    id: "layout-4",
    type: LayoutType.PORTRAIT,
    isLandscape: true,
    numberOfColumns: 2,
    imgSide: "left",
    imgOrder: "first",
    imgOutside: false,
  },
  {
    id: "layout-5",
    type: LayoutType.PORTRAIT,
    isLandscape: true,
    numberOfColumns: 2,
    imgSide: "left",
    imgOrder: "last",
    imgOutside: false,
  },
  {
    id: "layout-6",
    type: LayoutType.PORTRAIT,
    isLandscape: true,
    numberOfColumns: 2,
    imgSide: "right",
    imgOrder: "last",
    imgOutside: false,
  },
  {
    id: "layout-7",
    type: LayoutType.PORTRAIT,
    isLandscape: true,
    numberOfColumns: 3,
    imgSide: "left",
    imgOrder: "first",
    imgOutside: false,
  },
  {
    id: "layout-8",
    type: LayoutType.PORTRAIT,
    isLandscape: true,
    numberOfColumns: 3,
    imgSide: "left",
    imgOrder: "last",
    imgOutside: false,
  },
  {
    id: "layout-9",
    type: LayoutType.ONLY_TEXT,
    isLandscape: true,
    numberOfColumns: 1,
  },
  {
    id: "layout-10",
    type: LayoutType.ONLY_TEXT,
    isLandscape: true,
    numberOfColumns: 2,
  },
  {
    id: "layout-11",
    type: LayoutType.ONLY_TEXT,
    isLandscape: true,
    numberOfColumns: 3,
  },
  {
    id: "layout-12",
    type: LayoutType.FULL_IMAGE,
    isLandscape: true,
    numberOfColumns: 2,
    imgSide: "full",
    imgOrder: "last",
    imgOutside: true,
  },
];

export function getLayoutConfig(
  imgWidth: number,
  imgHeight: number,
  imgOrientation: string,
  reportMediaType?: string
) {
  if (reportMediaType === "CSV") {
    return layoutConfigs.filter(
      (config) =>
        config.type === LayoutType.LANDSCAPE ||
        config.type === LayoutType.FULL_IMAGE
    );
  }
  if (!imgHeight && !imgWidth) {
    return layoutConfigs.filter(
      (config) => config.type === LayoutType.ONLY_TEXT
    );
  }

  const aspectRatio = Number((imgWidth / imgHeight).toFixed(2));

  if (
    aspectRatio >= 3.3 &&
    aspectRatio <= 3.6 &&
    imgOrientation === "landscape"
  ) {
    return layoutConfigs.filter(
      (config) =>
        config.type === LayoutType.LANDSCAPE ||
        config.type === LayoutType.FULL_IMAGE
    );
  }

  if (imgOrientation === "landscape") {
    return layoutConfigs.filter(
      (config) => config.type === LayoutType.LANDSCAPE
    );
  }

  if (imgOrientation === "portrait") {
    return layoutConfigs.filter(
      (config) => config.type === LayoutType.PORTRAIT
    );
  }

  return [];
}
