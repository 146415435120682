import React from "react";
import { Tailwind, CSS } from "@fileforge/react-print";
import {
  Chapter,
  ReportMediaType,
  Subchapter,
  createDefaultGeneratedContent,
  createDefaultUserInput,
} from "../shared/types";
import { layoutConfigs } from "./templates/layoutConfigs";
import { SubchapterContent } from "./templates/SubchapterContent";
import { ChapterCover } from "./templates/ChapterCover";
import chapterCoverImage from "../assets/images/chapter-cover.jpg";

interface ChapterPreviewProps {
  chapters: Chapter[];
  header: string[];
  isPdf?: boolean;
  isLandscape?: boolean;
  selectedVersions: { [key: string]: number };
  tempVersion: { [key: string]: any };
  companyName: string;
}

const ChapterPreview: React.FC<ChapterPreviewProps> = ({
  chapters = [],
  header = [],
  isPdf = false,
  isLandscape = true,
  selectedVersions,
  tempVersion,
  companyName,
}) => {
  return (
    <React.Fragment>
      <CSS>
        {String.raw`
          @page {
            size: ${isLandscape ? "A4 landscape" : "A4 portrait"};
            margin: 2cm;
          }

          body {
            line-height: 1.1;
          }
        `}
      </CSS>
      <Tailwind>
        <div>
          {chapters.map((chapter, chapterIndex) => (
            <div key={chapterIndex}>
              <div
                className={`${
                  isPdf
                    ? ""
                    : "h-[calc((50vw-42px)/1.41)] border border-gray-300 mb-4 p-[0px] px-[15px] pb-[15px] shadow-md overflow-hidden rounded-lg"
                }`}
              >
                <ChapterCover
                  isLandscape={isLandscape}
                  title={chapter.title}
                  description={chapter.description}
                  img={chapterCoverImage}
                  header={header}
                  isPdf={isPdf}
                  companyName={companyName}
                />
              </div>
              {chapter.subchapters.map(
                (subchapter: Subchapter, subchapterIndex) => {
                  const key = `${chapterIndex}-${subchapterIndex}`;
                  const selectedVersionIndex =
                    selectedVersions[key] ?? subchapter.versions.length - 1;
                  const latestVersion = tempVersion[key] ??
                    subchapter.versions[selectedVersionIndex] ?? {
                      userInputs: createDefaultUserInput(),
                      layout: "",
                      generatedContent: createDefaultGeneratedContent,
                    };
                  const layoutConfig =
                    layoutConfigs.find(
                      (config) => config.id === latestVersion.layout
                    ) || {};
                  const subchapterId = `chapter-${chapterIndex}-subchapter-${subchapterIndex}`;
                  const imgContent =
                    latestVersion.userInputs?.media?.type ===
                    ReportMediaType.CSV
                      ? latestVersion.generatedContent?.chartUrl
                      : latestVersion.userInputs?.media?.mediaContent;

                  return (
                    <React.Fragment key={subchapterId}>
                      <div
                        id={subchapterId}
                        className={`${
                          isPdf
                            ? ""
                            : "h-[calc((50vw-42px)/1.41)] border border-gray-300 mb-4 p-[0px] px-[15px] pb-[15px] overflow-hidden rounded-lg"
                        }
                        `}
                      >
                        <SubchapterContent
                          {...layoutConfig}
                          imgDetails={
                            latestVersion.userInputs?.media?.imgDetails
                          }
                          content={latestVersion.generatedContent?.text}
                          img={imgContent}
                          caption={latestVersion.userInputs?.media?.caption}
                          header={header}
                          isPdf={isPdf}
                          title={subchapter.title}
                          companyName={companyName}
                        />
                      </div>
                    </React.Fragment>
                  );
                }
              )}
            </div>
          ))}
        </div>
      </Tailwind>
    </React.Fragment>
  );
};

export default ChapterPreview;
