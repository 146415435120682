export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER = "SET_USER";
export const RESET_USER = "RESET_USER";

export type User = {
  username: string;
  email: string;
  password: string;
};

export type Report = {
  _id: string;
  user_id: string;
  title: string;
  companyName: string;
};

export type UserWithReports = {
  user: User;
  reports: Report[];
};

export type Credentials = {
  username: string;
  password: string;
};

export type UserAction<T> = {
  type: string;
  payload?: T;
};

export function login(data: UserWithReports): UserAction<UserWithReports> {
  return {
    type: LOGIN_USER,
    payload: data,
  };
}

export function logout(): UserAction<never> {
  return {
    type: LOGOUT_USER,
  };
}

export function setUser(data: UserWithReports): UserAction<UserWithReports> {
  return {
    type: SET_USER,
    payload: data,
  };
}

export function resetUser(): UserAction<never> {
  return { type: RESET_USER };
}
