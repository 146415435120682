import { useEffect } from "react";
import { attemptLogout } from "../../store/thunks/auth";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export default function LogoutPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(attemptLogout(navigate));
    navigate("/home", { replace: true });
  }, [dispatch, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md text-center">
        <p>{t('logoutInProgress')}...</p>
      </div>
    </div>
  );
}
